import React from "react"
import GlobalStyles from "../styles/global-style"

import Content from "../components/Content/Content"
import Layout from "../layout/Layout"
import ContactUs from "../components/Misc/ContactUs"
import InVooDoo from "../components/Misc/Invoodoo"

const PrivacyPolicy = () => (
  <>
    <GlobalStyles />

    <Layout>
        <Content>
            <h1>Privacy Policy</h1>

            Read the End User License Agreement<br/>
            Last Updated: December 26, 2019<br/>
            <h2>OUR COMMITMENT TO YOU</h2>
            <p>
              At InVooDoo, your privacy is a top priority. We appreciate that you put your trust in us when you provide us with your information and we do not take this lightly.
              We do not compromise with your privacy. We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security and others to make sure that no decision is taken without respect for your privacy.
              We strive to be transparent in the way we process your data. Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. We take the exact opposite approach: we have written our Privacy Policy and related documents in plain language. We actually want you to read our policies and understand our privacy practices!
              We work hard to keep your information secure. We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.
            </p>
            <h3>1. INTRODUCTION</h3>
            Welcome to InVooDoo Privacy Policy. Thank you for taking time to read it!
            We appreciate that you trust us with your information and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.<br/>
            <InVooDoo/>
            
            <h3>2. WHERE THIS PRIVACY POLICY APPLIES</h3>
            <p>
              This Privacy Policy applies to websites, apps and other services operated by InVooDoo. For simplicity, we refer to all of these as our "services" or "apps" in this Privacy Policy. To make it extra clear, we've added links to this Privacy Policy on all applicable services.
            </p>  
            <h3>3. INFORMATION WE COLLECT</h3>
            <p>
              It goes without saying, we can't help you have an awesome user experience without some information about you, such as your email address and password in some of our services. We also collect information generated as you use our services, as well as information from third parties, like when you access our services through your Facebook account in Weather Live. Your information may be kept in an identifiable format, or in an aggregate format which means that you cannot reasonably be identified from it.
              Information you give us</p>

              <p>You choose to give us certain information when using our services. This includes:</p>
              <ol>
                <li>When you create an account in some of our services, you provide us with your login credentials, such as email address, name and password.</li>
                <li>To use certain features in our services, you may allow us to access your camera or the microphone. Depending on your device, Apple and Android system will ask for your permission before allowing us to access these features.</li>
                <li>Features that allow you to share content about your use of our services with your connections or through your social network. We may also collect your information when you use our app such as when you post or share material, upload data, request further services or make selections using our app, if such functionality is available in the app.</li>
                <li>In our apps, please note that anything you input into the app  is stored on your device and not accessible by InVooDoo. However, in order to understand if our users are enjoying the app, we do receive anonymized statistics from our analytics partners about what features users like and don't like. Should you wish to sync data held in your Apple "Health" app, we will ask you for your explicit permission to do this.</li>
                <li>From time to time, we may request your opinions, suggestions, or modification and improvement ideas ("Feedback") through surveys, or other similar requests. Any responses you provide are voluntary, however you agree that any Feedback you provide shall be owned InVooDoo and may be used by InVooDoo in our sole discretion.</li>
                <li>Information we receive from others</li>
              </ol>
              <p>In addition to the information you provide us directly, we receive information about you from others, including:</p>
              <ol>
                <li>Social Media</li>
                <li>You may be able to use your Facebook account to login to create an account. When you use this feature, we may receive any of your information you provide or permit to be provided to us via a social network or public forum provider like Facebook. This data may include your use of our app on such public forums and/or social networks, including with whom you share our app. For further information as to how and for what purpose the social network or public forum provider collects, uses and shares your data, as well as how you can set your privacy preferences on their site or apps, please see their privacy policy.</li>
                <li>Other Partners</li>
                <li>We may receive information about you from our partners, for instance where our ads are published on a partner's website or platform (in which case they may pass along details on a campaign's success).</li>
                <li>If you purchase a subscription in our apps, neither Google nor Apple provide us with any personal information of the subscription purchaser (like name, email or payment information). Only anonymized data gets passed to InVooDoo.</li>
                <li>Information collected when you use our services</li>
              </ol>
              <p>When you use our services, we collect information about which features you've used, how you've used them and the devices you use to access our services. See below for more details:</p>
              <ol>
                <li>Usage Information</li>
                <li>We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, time spent in the app, features you've been using, advertising that you click on).</li>
                <li>Movement Data: If you opt-in, some services may collect movement data from your device.</li>
                <li>Device information</li>
              </ol>
              <p>We collect information from and about the device(s) you use to access our services, including:</p>
              <ol>
                <li>geolocation data (in some of our apps), which is any information which indicates the geographical position of your device and which may include the time the location was recorded, and may include precise (e.g., GPS-level) location data. Some apps may collect this data when the app is not open or actively in use. This may help us or third parties to customize information, content, or offers to you, for instance.</li>
                <li>information on your wireless and mobile network connection, like your service provider and signal strength.</li>
                <li>hardware and software information such as IP address, device type, app crashes, app version, advertising IDs (such as Google's AdvertisingID and Apple's IDFA, both of which are randomly generated numbers that you can reset by going into your device's settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address).</li>
              </ol>
            <h3>4. COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES</h3>
            <p>
                As described in Sections 3 and 5 of this Privacy Policy, we sometimes work with third parties to deliver ads or offers. Sometimes we or these third parties do so through a mobile "SDK." The SDK is a bit of computer code that app developers can include in their apps to enable data to be collected and ads to be shown. We may use this and similar technology to deliver certain advertising through mobile applications and browsers based on data associated with your mobile device, and we may work with third parties who likewise embed their SDKs into our services, for third party advertising. If you'd like to opt-out from having ads tailored to you in this way on your mobile device, please follow the instructions below or in the app (as applicable).
                If you wish to opt out of interest-based tracking through your mobile device, you can do so through your device "settings", as follows (but please note that the below is for informational purposes only and Apple, Google, or other device platforms may change the manner in which their "opt out" settings work at any time): Please note that if you opt out through these settings, you will continue to receive advertising after you opt-out, but it may not be tailored to your interests. For instance, that advertising may be based on your real-time or "contextual" application usage or general location inferred from IP address.
                Opting Out on iOS Devices Such as iPhones, iPads or iPods
                If you have an Apple device, you can opt out of interest-based advertising by updating to iOS 6.0 or higher, and setting Limit Ad Tracking to 'ON'. On an iPhone 6 for example, you can do this by clicking on Settings &rarr; General &rarr; Privacy &rarr; Advertising and toggling "Limit Ad Tracking" to 'ON'. Our systems are designed to respect your choice and not use your personal information to provide interest-based advertising when this setting is ON.
            </p>

            <h3>5. HOW WE USE INFORMATION</h3>
            <p>
              The main reason we use your information is to deliver and improve our services. Additionally, we may use your information to help keep you safe and to communicate with you. Read on for a more detailed explanation of the various reasons we use your information, together with practical examples.
              We DO NOT use your non-personal information (meaning information that, by itself, does not identify who you are such as device information) for targeted online marketing through tools like Facebook Custom Audience to contact interested parties.</p>
              <p>To administer your account and provide our services to you</p>
              <ol>
                <li>Deliver our services to you and ensure their optimal functionality and operation.</li>
                <li>Identify, establish and manage your account and our relationship with you.</li>
                <li>Provide you with customer support and respond to your requests.</li>
                <li>Should you provide us with this information, we may use your email address, name, country and language for customized marketing purposes.</li>
                <li>When we collect your precise geolocation data (subject to your consent) (which may be via the device's cellular, Wi-Fi, Global Positioning System (GPS) networks and/or Bluetooth information), we do so to provide you with our location-related products and services, for example to provide you with forecast and weather alerts for your location, and also to facilitate, measure, or otherwise support advertising by third parties (through our apps or third parties' apps) that may be related to your location over time.</li>
                <li>Some of our apps may offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the app you are using, and on the social media provider concerned, but may often include your name, e-mail address, mobile phone number and friends list. If you login using your Facebook account, your e-mail or your phone number then we may receive a unique ID in connection with this login. This is solely used for confirmation purposes and is kept separate from all other online identifiers. We will use the information we receive only for the purposes that are described in this Privacy Policy or that are otherwise made clear to you in the app you are using. Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you see their privacy policy to understand how they collect, use and share your information, and how you can set your privacy preferences on their sites and apps.</li>
                <li>If you have registered with an app using your social media account details, we may use the information we receive from your social media provider to identify which of your friends are also using our services so that you can connect with them in our app.</li>
              </ol>
              <p>In some services, to ensure a consistent experience across your devices</p>
              <ol>
                <li>Link the various apps and devices you use, so that you can enjoy a consistent experience of our services on all of them. This will allow us to form a single view of your use of our services, so you can easily access your content on multiple devices. For this purpose and better user experience generally, we may use and combine your personal information which we collect across different services used by you.</li>
              </ol>
              <p>To improve our services and develop new ones</p>
              <ol>
                <li>Develop new features and services (for example, we may decide to build a new feature further to requests or feedback received from users).</li>
                <li>InVooDoo uses the Google Analytics tool Firebase in order to carry out analysis on information which is gathered by our apps. Device information and other information which we gather are provided to Google in order to enable them to carry out data analysis. Any information which is provided to Google will become subject to Google's privacy policy which is located here: www.google.com/privacy.html. For further information on how Google's Firebase analytics tool uses data, please see the site "How Google uses data when you use our partners' sites or apps", (located at www.google.com/policies/privacy/partners/).</li>
                <li>Conduct research and analysis of your behaviour about your use of, or interest in, our products, services, or content to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify a given feature based on users' behaviour). This software may record information such as how often you use the services, what happens within the services, aggregated usage, performance data, app errors and debugging information, and where the apps were downloaded from.</li>
              </ol>
              <p>To communicate with you</p>
              <ol>
                <li>Communicate with you by postal mail, telephone and/or mobile devices (including through in-application advertising) about products or services that may be of interest to you either from us, our parent or subsidiary companies or other third parties. If you do not wish to receive in-application advertising, in some cases you can upgrade to the paid version of the app.</li>
                <li>When you share your information with us via a social network provider like Facebook, we use this to show information on your social network profile (subject to your privacy setting and preferences for such social network) and to allow you to share information about our services with your connections or on your social network profile.</li>
                <li>With your consent (where needed), to send you email marketing in order to provide you with information about services that you may find of interest. You can opt-out of receiving direct marketing communications from us by following the unsubscribe instructions included in our marketing communications. We also send you non-marketing emails or messages, such as those related to transactions, your account, security, or product changes. Examples of service-related messages include an email address confirmation/welcome email when you register your account, service availability or modification of key features or functions.</li>
              </ol>
              <p>To serve you relevant offers and ads</p>
              <ol>
                <li>Develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness. When you are on our services, third-party ad partners may provide you with advertising based upon your interests. These companies may use technologies to collect data about users, including precise device location data, to help them tailor relevant ads and measure ads' effectiveness. These companies may collect this data through SDKs or from users who view or interact with their advertisements. For the purposes of European law, most of these ad partners are independent controllers of your information, which means that they are responsible for providing and complying with their own policies relating to any personal information they obtain in connection with the services. It also means that if you are a Facebook app user for example – you provided consent to Facebook in your Facebook app to allow them to show you personalized ads inside of our services.</li>
                <li>Verify your eligibility and deliver prizes in connection with contests and sweepstakes and in order to conduct polls and surveys;</li>
              </ol>
              <p>To prevent, detect and fight fraud or other illegal or unauthorized activities</p>
              <ol>
                <li>Perform data analysis to better understand and design countermeasures against these activities</li>
                <li>Retain data related to fraudulent activities to prevent against recurrences</li>
              </ol>
              <p>To ensure legal compliance</p>
              <ol>
                <li>Comply with legal requirements</li>
                <li>Assist law enforcement</li>
                <li>Enforce or exercise our rights, for example our EULA</li>
              </ol>
              <p>To process your information as described above, we rely on the following legal bases:</p>
              <ol>
                <li>Provide our service to you: Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service, we use your information to maintain your account and provide our cool features to you.</li>
                <li>Legitimate interests: We may use your information where we have legitimate interests to do so. For instance, we analyze users' behavior on our services to continuously improve our offerings and we process information for administrative, fraud detection and other legal purposes.</li>
                <li>Consent: From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time where indicated in the services or by contacting us at the address provided at the end of this Privacy Policy.</li>
              </ol>
            <h3>6. HOW WE SHARE YOUR INFORMATION</h3>
            <p>
              Since our goal is to provide the best services we can to you, the main sharing of users' information is with service providers and partners who assist us in operating the services, with other IAC Group companies and, in some cases, legal authorities. Read on for more details about how your information is shared with others.
              With our service providers and partners</p>
            <ol>
              <li>We follow a strict vetting process prior to engaging any service provider or working with any partner. We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, marketing, advertising and security operations. These service providers may have limited access to your information, which is needed to perform their functions, but are not permitted to share or use such information for any other purposes. For example, we use your non-personal information (meaning information that, by itself, does not identify who you are such as device information) for targeted online marketing through tools like Facebook Custom Audience to contact interested parties.</li>
              <li>In addition, we may share aggregated information we collect, under any of the above circumstances, with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis or other legitimate business purposes. Please note that such aggregated information will not include any information which allows third parties to identity you.</li>
              <li>For corporate transactions</li>
              <li>We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.</li>
              <li>When required by law</li>
              <li>We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; (ii) in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; or (iii) to protect and defend the rights, property or safety of our company, our users, our employees, or others.</li>
              <li>To enforce legal rights</li>
              <li>We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties and/or to comply with applicable law; (iii) to enforce our agreements or policies with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.</li>
              <li>With your consent or at your request</li>
              <li>We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.</li>
            </ol>

            <h3>7. HOW WE PROTECT YOUR INFORMATION</h3>
            <p>
              The security of your personal information is important to us. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and after it is received. We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information will always remain secure. We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information collection, storage and processing practices to update our physical, technical and organizational security measures.
              We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure, please notify us immediately at <ContactUs email="support@invoodoo.com"/>
            </p>

            <h3>8. INFORMATION RELATING TO CHILDREN</h3>
            <p>
              Our services are intended for general audiences over the age of 13 years old (or over the age of 16 if you are resident in the EEA). We do not knowingly collect information from children under the age of 13 years old, or 16 years old in the EEA. If you are not over 13 years old (or 16 years old in the EEA) then DO NOT DOWNLOAD OR USE THE SERVICES. If you believe that we may have personal information from or about a child under the age of 13 years old (or over the age of 16 if you are resident in the EEA), please contact us at <ContactUs email="support@invoodoo.com"/> and we will promptly delete that personal information
            </p>

            <h3>9. YOUR RIGHTS</h3>
            <p>We want you to be in control of your information and be aware of your privacy rights, so here are a few key points to remember:</p>
            <ol>
              <li>Depending on your location you may have some or all of the following rights in relation to how we use your personal information:</li>
              <li>Access: you may request access to your personal information and receive copies of it;</li>
              <li>Correction: you may have inaccurate/incomplete personal information corrected and updated;</li>
              <li>Object to, or Limit or Restrict, Use of Data: you can ask us to stop using all or some of your personal information or to limit our use of it.</li>
              <li>Deletion: in certain circumstances, you can request a right "to be forgotten" (this is a right to have your information deleted or our use of your data restricted). We will honour such requests unless we have to retain this information to comply with a legal obligation or unless we have an overriding interest to retain it.</li>
              <li>Portability: in certain circumstances, exercise the right to data portability (this is a right to obtain a transferable version of your personal information to transfer to another provider)</li>
              <li>Consent Management: where we rely on consent to process your personal data, you may withdraw consent at any time. You do not have to provide a reason for your withdrawal where processing is based on consent.</li>
              <li>If you wish to exercise any of these rights, or wish to object to our use of your information, please write to us at the address listed below and we will consider your request in accordance with applicable laws.</li>
              <li>Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on the rights and freedoms of another person. For example, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore.</li>
              <li>For your protection, we may ask you to provide proof of identity before we can answer the above requests.</li>
            </ol>
            <h3>10. CROSS-BORDER DATA TRANSFERS</h3>
            <p>
              Sharing of information sometimes involves cross-border data transfers, for instance to the United States of America and other jurisdictions. The United States, European Economic Area ("EEA") Member States, and other countries all have different laws. When your information is moved from your home country to another country, the laws and rules that protect your personal information in the country to which your information is transferred may be different from those in the country in which you live.
              The European Commission has adopted standard contractual clauses (also known as Model Clauses), which provide safeguards for personal information that is transferred outside of the EEA. We use these Model Clauses or other suitable safeguards (such as EU-US Privacy Shield to send personal information to some of our third party service providers in the United States, where they are certified to receive such information under the Privacy Shield Program) to permit data transfers from the EEA to other countries.
            </p>

            <h3>11. HOW LONG WE STORE YOUR INFORMATION</h3>
            <p>We retain your information only for as long as is necessary for the purposes set out in this policy, for as long as your account is active or as needed to provide the services to you. We will also retain and use your information to the extent necessary:</p>
            <ol>
              <li>to comply with applicable law;</li>
              <li>to evidence our compliance with applicable law;</li>
              <li>if there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or</li>
              <li>if the information must be kept for our legitimate business interests, such as enforcing our terms or fraud prevention. For example, information may need to be kept to prevent a user who was banned for security incidents from opening a new account.</li>
            </ol>
            <p>Keep in mind that even though our systems are designed to carry out data deletion processes according to the above guidelines, we cannot promise that all data will be deleted within a specific timeframe due to technical constraints.</p>
            <h3>12. PRIVACY POLICY CHANGES</h3>
            <p>
              Because we're always looking for new and innovative ways to help you enjoy our services, this policy may change over time. We will notify you of any material changes so that you have time to review the changes.
            </p>

            <h3>13. DATA PROTECTION OFFICER</h3>
            <p>
              For our European users, our Data Protection Officer is responsible for ensuring your rights are respected and to review and oversee how we collect and use your personal information. They can be reached by contacting <ContactUs email="contact@invoodoo.com"/>
            </p>

            <h3>14. HOW TO CONTACT US</h3>
            <p>
              If you have questions about this Privacy Policy, here's how you can reach us:<br/>
              Online: <ContactUs email="contact@invoodoo.com"/>
            </p>
            <p>
              If you live in the EEA, you may also file a complaint with your local data protection regulator.
              We have recently updated our policies and we are in the process of translating it into several languages. If you do not have an appropriate language option and you have any questions, please contact us.
            </p>  
        </Content>
    </Layout>
  </>
)

export default PrivacyPolicy